/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://github.com/tailwindcss/tailwindcss/blob/master/css/preflight.css
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/preflight";
 */
@tailwind preflight;

/**
 * This injects any component classes registered by plugins.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/components";
 */
@tailwind components;

/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "components/buttons";
 * @import "components/forms";
 */

 @font-face {
	font-family: "Gilroy";
	src: url("/assets/fonts/Gilroy-Light.otf") format("truetype")
	  url("/assets/fonts/Gilroy-Light.ttf") format("truetype"),
	  url("/assets/fonts/Gilroy-Light.woff2") format("woff2"),
	  url("/assets/fonts/Gilroy-Light.woff") format("woff");
	  font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Gilroy";
	src: url("/assets/fonts/Gilroy-ExtraBold.otf") format("truetype")
	url("/assets/fonts/Gilroy-ExtraBold.ttf") format("truetype"),
	url("/assets/fonts/Gilroy-ExtraBold.woff2") format("woff2"),
	url("/assets/fonts/Gilroy-ExtraBold.woff") format("woff");
	font-weight: 700;
		font-style: normal;
}

@font-face {
	font-family: "GilroyBold";
	src: url("/assets/fonts/Gilroy-ExtraBold.otf") format("truetype")
	url("/assets/fonts/Gilroy-ExtraBold.ttf") format("truetype"),
	url("/assets/fonts/Gilroy-ExtraBold.woff2") format("woff2"),
	url("/assets/fonts/Gilroy-ExtraBold.woff") format("woff");
	font-weight: normal;
		font-style: normal;
}

  @font-face {
	font-family: "Lemur";
	src: url("/assets/fonts/Lemur-Regular.ttf") format("opentype"),
	url("/assets/fonts/Lemur-Regular.ttf") format("truetype"),
	url("/assets/fonts/Lemur-Regular.woff2") format("woff2"),
	url("/assets/fonts/Lemur-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
  }
  @font-face {
	  font-family: "Lemur";
	  src: url("/assets/fonts/Lemur-Bold.otf") format("truetype"),
	  url("/assets/fonts/Lemur-Bold.ttf") format("truetype"),
	  url("/assets/fonts/Lemur-Bold.woff2") format("woff2"),
	  url("/assets/fonts/Lemur-Bold.woff") format("woff");
	  font-weight: 700;
	  font-style: normal;
  }
  @font-face {
	font-family: "LemurBold";
	src: url("/assets/fonts/Lemur-Bold.otf") format("truetype"),
	url("/assets/fonts/Lemur-Bold.ttf") format("truetype"),
	url("/assets/fonts/Lemur-Bold.woff2") format("woff2"),
	url("/assets/fonts/Lemur-Bold.woff") format("woff");
	font-weight: normal;
	font-style: normal;
  }

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/utilities";
 */
@tailwind utilities;

@responsive {
	// Used to overlay a 87px tall element over the element before it by shifting its position upwards.
	// Use in conjunction with u-relative.
	.u--overlay-87 {
		top: calc((87px / 2) * -1);
	}
}

.u-border-box {
	box-sizing: border-box !important;
}

[v-cloak] {
	display: none !important;
}

.u-transition {
	transition: all;
}

.u-not-italic {
	font-style: normal !important;
}